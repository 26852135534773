import { Component } from '@angular/core';
import { environment } from './../environments/environment';
import { SessionService } from "./stores/session/session.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Paygle';

  constructor() {
  }

  ngOnInit() {

  }
}
