const SESSION_KEY = 'session';
const INVOICE_KEY = 'invoice'
const PAYMENT_KEY = 'payment'

export function getSession() {
  const session = localStorage.getItem(SESSION_KEY);
  return session ? JSON.parse(session) : {};
}

export function saveSession(session) {
  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
}

export function clearSession() {
  localStorage.removeItem(SESSION_KEY);
}

export function getInvoiceDetail() {
  const invoiceDetail = localStorage.getItem(INVOICE_KEY + '_detail');
  return invoiceDetail ? JSON.parse(invoiceDetail) : {};
}

export function setInvoiceDetail(invoiceDetail) {
  localStorage.setItem(INVOICE_KEY + '_detail', JSON.stringify(invoiceDetail));
}

export function getPaymentDetail() {
  const paymentDetail = localStorage.getItem(PAYMENT_KEY + '_detail');
  return paymentDetail ? JSON.parse(paymentDetail) : {};
}

export function setPaymentDetail(paymentDetail) {
  localStorage.setItem(PAYMENT_KEY + '_detail', JSON.stringify(paymentDetail));
}

export function getAnonymousInvoiceDetail(anonymousInvoiceDetail) {
  localStorage.setItem(INVOICE_KEY + '_anonymousDetail', JSON.stringify(anonymousInvoiceDetail));
}

export function getInvoiceStatus() {
  const invoiceStatus = localStorage.getItem(INVOICE_KEY + '_status');
  return invoiceStatus ? JSON.parse(invoiceStatus) : {};
}

export function setInvoiceStatus(invoiceStatus) {
  localStorage.setItem(INVOICE_KEY + '_status', JSON.stringify(invoiceStatus));
}

export function setRefundDetail(refundDetail) {
  localStorage.setItem(INVOICE_KEY + '_refund', JSON.stringify(refundDetail));
}

export function clearInvoiceDetail() {
  localStorage.removeItem(INVOICE_KEY + '_detail');
}

export function setOptions(options) {
  localStorage.setItem(INVOICE_KEY + '_options', JSON.stringify(options));
}

export function clearAll() {
  localStorage.removeItem(SESSION_KEY);
  localStorage.removeItem(INVOICE_KEY + '_detail');
  localStorage.removeItem(INVOICE_KEY + '_anonymousDetail');
  localStorage.removeItem(INVOICE_KEY + '_status');
  localStorage.removeItem(INVOICE_KEY + '_options');
}
