import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as storage from '../stores/storage';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = storage.getSession().basicToken;
    let httpHeader = new HttpHeaders();
    httpHeader = httpHeader.set("Accept", "application/json");
    httpHeader = httpHeader.set("Access-Control-Allow-Origin", "*");
    if (request.method !== '"GET"') {
      httpHeader = httpHeader.set('Content-Type', 'application/json');
    }

    if (!_.isEmpty(accessToken)) {
      httpHeader = httpHeader.set('Authorization', `Basic ${accessToken}`);
    }

    request = request.clone({
      headers: httpHeader
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        //401 UNAUTHORIZED
        if (error && error.status === 401) {
          console.log("ERROR 401 UNAUTHORIZED")
        }

        return throwError(error);
      })
    );
  }
}
